import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';
import { customerEnvironment } from '$lib/environment';
import { init as SentryInit, captureException } from '@sentry/svelte';
import { BrowserTracing } from '@sentry/tracing';

if (!dev) {
  SentryInit({
    dsn: 'https://9a3d4596f36149d181b9a47f9986f8ea@logging.darwin-cloud.com/8',
    release: __version__,
    environment: customerEnvironment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export const handleError: HandleClientError = ({ error }) => {
  console.error(error);

  if (!dev) {
    captureException(error);
  }
};
